@import '../../constants/ColorStyles.scss';

.new-banner {
  width: 100%;
  .drop-d {
    //width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    color: #103c68;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 5px;
    label {
      margin: 5px 0;
    }
  }
  .production-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 95%;

    .info-icon {
      margin-left: 8px;
      cursor: pointer;
      font-size: 1.2rem;
      color: #999;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffdd00;
      position: absolute;
      right: -40px;
      bottom: 8px;
    }

    .info-icon:hover {
      color: #333;
      border-color: #333;
    }
  }
  .textarea-container {
    width: 100%;
  }
  .new-banners {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 50px;
    padding-bottom: 50px;
    .input-fields-a {
      padding-top: 20px;
      //display: flex;
      //align-items: center;
      // flex-direction: column;
      height: 97%;
      width: 100%;
      .inputStyle {
        width: 99%;
        height: 40px;
        border-radius: 8px;
        font-size: 15px;
        padding-left: 13px;
        border: 1px solid $dark-blue;
      }
      .dropdown {
        display: flex;
        align-items: center;
        text-align: start;
        width: 99%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid $dark-blue;
      }
      .input-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        width: 100%;
        .label {
          color: $dark-blue;
          font-weight: 500;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
      .upload-image-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-top: 8px;
        width: 90%;
        .label {
          color: $dark-blue;
          font-weight: 500;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }

      .upload-image {
        border-width: 0.15rem;
        border-color: $dark-blue;
        border-style: dashed;
        width: 99%;
        min-height: 150px;
        border-radius: 8px;
        .p-progressbar-label {
          display: none !important;
        }
        .progress-bar {
          width: 70%;
          background-color: red;
        }
        // margin-top: 100px;
      }
      .inner-button-container {
        display: flex;
        justify-content: flex-end;
        width: 90%;
        margin-top: 20px;
        height: 40px;
        .btn {
          cursor: pointer;
          width: 140px;
          height: 100%;
          margin-right: 10px;
          background-color: $dark-blue;
          color: $white;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
        }
        .btn-disabled {
          cursor: not-allowed;
          width: 140px;
          height: 100%;
          margin-right: 10px;
          background-color: rgba(1, 46, 91, 0.7);
          color: $white;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
        }
      }
    }
    .image-container {
      display: flex;
      justify-content: center;
      width: 30%;
      min-width: 420px;
      align-items: flex-start;
      .inner {
        position: relative;
        width: 30%;
        min-width: 420px;
        .image {
          width: 80%;
          max-width: 330px;
        }
        .banner {
          position: absolute;
          bottom: 10%;
          left: 11.08%;
          width: 79%;
          max-width: 327px;
          max-height: 100px;
        }
      }
    }
  }
}
