@import '../../constants//ColorStyles.scss';

.login-page {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100vh;
    background-color: $dark-blue;
    .inner-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .first-row {
        width: 70%;
        .inputContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }
        .margin {
          margin-top: 10px;
        }
        .input {
          padding-left: 10px !important;
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
        .message {
          padding-top: 10px;
          padding-bottom: 10px;
          min-height: 20px;
          line-height: 10px;
          width: 100%;
          color: red;
          font-size: 13px;
      }
        .input-error {
          padding-left: 10px !important;
          font-size: 15px;
          border-color: red;
          width: 97.5%;
          height: 40px;
          border-radius: 6px;
        }
        .wrapper{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          height: 110px;
          width: 100%;
        }
        .label {
          color: $white;
          font-weight: 300;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
      .second-row {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-left: 10px;
        width: 70%;
        .remember-me{
          display: flex;
          flex-direction: row;
          align-items: center;
          .checkbox{
            cursor: pointer;
            width: 18px;
            height: 18px;
          }
          label {
            margin-left: 3px;
            color: $white;
            font-weight: 300;
          }
        }
        .forgot-password{
          cursor: pointer;
          margin-right: 10px;
          color: $white;
          font-weight: 300;
          text-decoration: underline;
        }
      }
      .line {
        margin-top: 30px;
        width: 70%;
      }
      .button{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        width: 70%;
        height: 40px;
        border-radius: 6px;
        border: none;
        background-color: $yellow;
        font-size: 22px;
        font-weight: 800;
        cursor: pointer;
        color: $dark-blue;
      }
    }
  }
  .amss-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    height: 100vh;
    background-color: $yellow;
    .image{
      max-width: 40%;
      max-height: 60%;
    }
  }
}
