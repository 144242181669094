@import '../../constants/ColorStyles.scss';

.amss-page {
  position: relative;
  min-height: 100%;
  // border-left: 7px solid $color-main-border;
  display: flex;
  flex-direction: column;
  .amss-page-content {
    display: flex;
    flex-grow: 1;

    .amss-main-menu-wrapper {
      min-width: 280px;
      min-height: calc(100vh - 70px);
      border-right: 1px solid $dark-blue;
    }

    .amss-content-wrapper {
      flex: 1;
    }

  }
}
