@import '../../constants/ColorStyles.scss';

.edit-modal{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 25%;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
    .header-container{
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 5px;
        align-items: center;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-color: $dark-blue;
        height: 40px;
        .header {
            font-size: 20px;
            color: $white;
            margin-bottom: 5px;
        }
        .delete-btn{
            cursor: pointer;
            border: none;
            padding: 5px;
            border-radius: 50%;
            background-color: $dark-blue;
        }
    }
    .text{
        margin-top: 30px;
    }
    .btn-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 30px;
        .btn{
            cursor: pointer;
            border: none;
            border-radius: 10px;
            margin: 5px;
            height: 100%;
            width: 80px;
            color: $white;
        }
        .red {
            background-color: $red;
        }
        .blue {
            background-color: $dark-blue;
        }
        .blue-text {
            color: $dark-blue;
        }
        .yellow {
            background-color: $yellow;
        }
    }
}