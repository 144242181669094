@import '../../constants/ColorStyles.scss';

.breadcrumb{
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    .header{
        color: $dark-blue;
        font-weight: bold;
        font-size: 20px;
        padding-top: 20px;
        padding-left: 25px;
    }
}