@import '../../constants/ColorStyles.scss';

.user-header-container {
    background-color: $yellow;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .user-header-profile-image {
        flex-grow: 3;

        .user-header-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            margin-left: .5rem;
        }
    }

    .user-header-data {
        margin: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-grow: 9;
        .user-header-name {
            font-weight: bold;
        }
    }
}
