@import '../../constants/ColorStyles.scss';

.notification-list {
  width: 100%;
  height: calc(100vh - 70px);
  .table-container {
    padding-top: 30px;
    padding-left: 25px;
    width: calc(100% - 25px);
    // height: 80%;

    .p-datatable-lg {
      .p-datatable-tbody > tr > td {
        padding: 0.45rem 1.25rem;
      }

      .p-button {
        display: none !important;
      }
      .p-paginator {
        color: $dark-blue !important;
        margin-top: 20px !important;
        cursor: pointer !important;
        button {
          color: $dark-blue !important;
          font-size: 15px;
        }
      }
      .p-column-header-content {
        display: flex;
        align-items: center;
        justify-content: center;
        .p-column-title {
          color: $dark-blue !important;
        }
      }

      .p-checkbox .p-component {
        width: 20px !important;
        height: 20px !important;
        border: 1px solid $dark-blue;
      }
      .p-checkbox .p-component[aria-checked='true'] {
        background-color: $dark-blue;
        color: $white;
      }
      .column-container {
        text-align: center !important;
        // width: 20% !important;
        height: 40px;
        color: $dark-blue !important;
        span {
          margin-top: 5px !important;
        }
      }
      .column-container-description{
        text-align: center !important;
        width: 20%;
      }
      .column-container-checkbox {
        text-align: left !important;
        .p-column-header-content {
          display: flex;
          padding-top: 10px;
          justify-content: flex-start;
        }
      }
      .column-container-options {
        width: 12%;
        padding-right: 10px;
        min-width: 140px;
      }
    }
    .options {
      display: flex;
      justify-content: center;
      width: 100%;
      // height: 40px;
      margin-bottom: 1px;
      position: relative;
      .inner-container {
        display: flex;
        justify-content: center;
        width: 70%;
        min-width: 140px;
        .button {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 5px;
          background-color: $dark-blue;
          border-color: $dark-blue;
        }
        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 5px;
          background-color: $dark-blue;
          border-color: $dark-blue;
          margin-right: 10px;
        }
      }
    }
    .button-delete-selected {
      position: absolute;
      top: 90px;
      right: 6.4%;
      z-index: 100;
      cursor: pointer;
      width: 35px;
      height: 35px;
      border-radius: 5px;
      background-color: $dark-blue;
      border-color: $dark-blue;
      @media only screen and (max-width: 1950px) {
        right: 120px;
      }
     
    }
  }

  .bottom-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    .inner-button-container {
      margin-right: 2%;
      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 20px;
        height: 100%;
        margin-right: 10px;
        background-color: $dark-blue;
        color: $white;
        font-size: 16px;
        font-weight: bold;
        border-radius: 10px;
      }
    }
  }
}
