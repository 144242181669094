@import '../../constants/ColorStyles.scss';

.header-container {
    height: 70px;
    display: block;
    overflow: hidden;
    background-color: $dark-blue;
    .header-logo {
        float: left;
        margin-top: 7px;
        margin-left: 15px;
    }

    .header-buttons {
        float: right;
        display: flex;
        align-items: center;

        >
         div {
            height: 70px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }
        .image{
            cursor: pointer;            
        }
        .header-button-label {
            cursor: pointer;
            padding: 0 10px;
            font-size: .8rem;
            color: yellow;
            margin-bottom: 2px;
        }
    }

    .header-user {
        float: right;
    }
}
