@import '../../constants//ColorStyles.scss';

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 70px);
  img {
    user-select: none;
    -webkit-user-drag: none;
  }
}
