@import '../../constants/ColorStyles.scss';

.sidebar {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  .item {
    width: 100%;
    user-select: none;
    cursor: pointer;
    .main-part {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      .header {
        display: flex;
        width: 50%;
        justify-content: flex-start;
        color: $dark-blue;
      }
      .img-arrow {
        width: 13px;
        height: 9px;
      }
    }

    .active {
      background-color: $dark-blue;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      .header {
        color: white;
        font-weight: 500;
      }
    }
    .sub-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .row {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        .inner-container {
          display: flex;
          align-items: center;
          padding-left: 60px;
          .img-sub-menu {
            margin-top: 4px;
          }
          .sub-menu-header {
            margin-left: 30px;
          }
        }
      }
      .active-sub-menu-class{
        background-color: $yellow;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          .sub-menu-header {
            font-weight: 500;
          }
      }
    }
  }
}
