@import '../../constants/ColorStyles.scss';

.input-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 90%;
  .label {
    color: $dark-blue;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
